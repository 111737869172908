import React, { useEffect, useRef, useState } from "react";
import { GridList } from "../../components/GridList/GridList";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ToastsStore } from "react-toasts";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import GridListActions from "../../store/ducks/GridList";
import { removeAccents } from "../../helpers/utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  prepareDataForPieChart,
  renderPieChart,
} from "../../helpers/chartUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  chartContainer: {
    minHeight: "fit-content !important",
    maxHeight: "100% !important",
    position: "relative",
  },
  actions: {
    justifyContent: "flex-end",
  },
  chart: {
    height: "100% !important",
    width: "100% !important",
  },
}));

const RelatorioTipo = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { tipoRelatorio } = location.state || "";
  const classes = useStyles();
  const dispatch = useDispatch();
  const gridData = useSelector((state) => state.gridDataReducer.gridData);
  const chartRefTipo = useRef(null);
  const chartRefStatus = useRef(null);
  const chartRefBairro = useRef(null);
  const [showTipoChart, setShowTipoChart] = useState(false);
  const [hasTipoData, setHasTipoData] = useState(false);
  const [hasStatusData, setHasStatusData] = useState(false);
  const [hasBairroData, setHasBairroData] = useState(false);

  let chartDataTipo;
  let chartDataStatus;
  let chartDataBairro;

  useEffect(() => {
    if (gridData && gridData.rows) {
      console.log("Grid Data:", gridData);
      
      // Preparar os dados para o gráfico
      const dataTipo = prepareDataForPieChart(gridData, (row) => row.tipo);
      const dataStatus = prepareDataForPieChart(gridData, (row) => row.status);
      const dataBairro = prepareDataForPieChart(gridData, (row) => row.bairro.name);

      // Verificar se há dados e atualizar o estado
      const tipoHasData = dataTipo && Object.keys(dataTipo).length > 0;
      const statusHasData = dataStatus && Object.keys(dataStatus).length > 0;
      const bairroHasData = dataBairro && Object.keys(dataBairro).length > 0;

      setHasTipoData(tipoHasData);
      setHasStatusData(statusHasData);
      setHasBairroData(bairroHasData);

      // Renderizar o gráfico se houver dados
      if (tipoHasData) {
        renderPieChart(chartRefTipo, dataTipo);
      } else {
        // Limpar o gráfico se não houver dados
        if (chartRefTipo.current) {
          chartRefTipo.current.getContext('2d').clearRect(0, 0, chartRefTipo.current.width, chartRefTipo.current.height);
        }
      }

      if (statusHasData) {
        renderPieChart(chartRefStatus, dataStatus);
      } else {
        // Limpar o gráfico se não houver dados
        if (chartRefStatus.current) {
          chartRefStatus.current.getContext('2d').clearRect(0, 0, chartRefStatus.current.width, chartRefStatus.current.height);
        }
      }

      if (bairroHasData) {
        renderPieChart(chartRefBairro, dataBairro, true);
      } else {
        // Limpar o gráfico se não houver dados
        if (chartRefBairro.current) {
          chartRefBairro.current.getContext('2d').clearRect(0, 0, chartRefBairro.current.width, chartRefBairro.current.height);
        }
      }
    }
  }, [gridData, hasBairroData]);

  const tipoRelatorioLimpo = removeAccents(tipoRelatorio);

  useEffect(() => {
    if (tipoRelatorioLimpo === "Todos os tipos") {
      setShowTipoChart(true);
      dispatch(GridListActions.setFilter([])); // Define o filtro como vazio para remover qualquer filtro anterior
    } else {
      dispatch(
        GridListActions.setFilter(["[tipo,contains", tipoRelatorioLimpo])
      );
    }
  }, [dispatch, tipoRelatorioLimpo]);

  const columns = [
    { name: "descricao", title: "Título", width: 130, filterable: true },
    { name: "detalhe", title: "Descrição", width: 130, filterable: true },

    {
      name: "empresa",
      title: "Empresa/Orgão",
      width: 130,
      filterable: true,
    },
    {
      name: "data",
      title: "Data",
      width: 130,
      filterable: true,
      getCellValue: (row) =>
        row.data ? new Date(row.data).toLocaleDateString() : null,
    },
    // { name: "", title: "Latitude", width: 130, filterable: true },
    // { name: "", title: "Longitude", width: 130, filterable: true },
    {
      name: "$bairro.name$",
      title: "Bairro",
      getCellValue: (row) => (row.bairro ? row.bairro.name : null),
      width: 150,
      filterable: true,
    },
    { name: "rua", title: "Rua", width: 130, filterable: true },
    { name: "cep", title: "CEP", width: 130, filterable: true },
  ];

  function onClick() {}

  const viewReportAction = {
    onClick: onClick,
    icon: "search",
    title: "/relatorio/detalhamento",
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item>
          <Card className="cardPersonalData">
            <CardHeader title={"Relatorio de " + tipoRelatorio} />
            <Divider />
            <CardContent>
              <Grid container spacing={3} wrap="wrap">
                <GridList
                  modelName={"Relatorio"}
                  endpoint={"bairro/eventos"}
                  enableActions={true}
                  columns={columns}
                  hiddenUpdateButton={true}
                  hiddenDeleteButton={true}
                  disableNewButtom={true}
                  addCustomAction={viewReportAction}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        {showTipoChart && hasTipoData && (
          <Grid item>
            <Card className="cardPersonalData">
              <CardHeader title={"Gráfico de Distribuição por Tipo"} />
              <CardContent className={classes.chartContainer}>
                <canvas className={classes.chart} ref={chartRefTipo} />
              </CardContent>
            </Card>
          </Grid>
        )}
        {hasStatusData && (
          <Grid item>
            <Card className="cardPersonalData">
              <CardHeader title={"Gráfico de Distribuição por Status"} />
              <CardContent className={classes.chartContainer}>
                <canvas className={classes.chart} ref={chartRefStatus} />
              </CardContent>
            </Card>
          </Grid>
        )}
        {hasBairroData && (
          <Grid item>
            <Card className="cardPersonalData">
              <CardHeader title={"Gráfico de Distribuição por Bairro"} />
              <CardContent className={classes.chartContainer}>
                <canvas className={classes.chart} ref={chartRefBairro} />
              </CardContent>
            </Card>
          </Grid>
        )}
        </Grid>
    </div>
  );
};

export default RelatorioTipo;
