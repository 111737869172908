import React, { useState, useEffect } from "react";
import { change } from "redux-form";
import api from "../../services/api";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  CardActions,
  Button,
} from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import Carousel from "../Carousel/Carousel";
import { ToastMessage } from "../../layouts";
import { uploadFile, remoteDeleteImage } from "../../services/images";
import { useSelector, useDispatch } from "react-redux";
import {
  addPesquisa,
  deleteFromTable,
  getTableToArray,
  sendDataToEndpoint,
} from "../../db";
import CarouselActions from "../../store/ducks/Carousel";
import ReCAPTCHA from "react-google-recaptcha";
import Carousel2 from "../Carousel/Carousel2";
import Creators from "../../store/ducks/GridListData";

let BasicForm = (props) => {
  const { handleSubmit, valid, reset, pristine, submitting } = props;
  const { id } = useParams();
  const history = useHistory();
  const [loadingEntity, setLoadingEntity] = useState(false);
  const [images, setImages] = useState([]);
  const [entity, setEntity] = useState(null);
  const [recaptched, setRecaptched] = useState(false);

  const dispatch = useDispatch();

  const formName = props.formName;
  const modelName = props.modelName;
  const label = props.label;
  const content = props.content;
  const imageContext = props.imageContext;
  const customAfterLoadEntity = props.customAfterLoadEntity;
  const keepUpdateAfterSubmit = props.keepUpdateAfterSubmit || false;
  const routeAfterSubmit = props.routeAfterSubmit;
  const customAfterInitialState = props.customAfterInitialState;
  const customAfterSubmit = props.customAfterSubmit;
  const loadImages = useSelector((state) => state.carousel.loadImages);
  const needRecaptch = props.needRecaptch || false;
  const hiddenNewButton = props.hiddenNewButton || false;
  const hiddenSaveButton = props.hiddenSaveButton || false;
  const hiddenBackButton = props.hiddenBackButton || false;
  const noLoadData = props.noLoadData || false;
  const maxImages = props.maxImages || 5;
  const hiddenRemoveImgButton = props.hiddenRemoveImgButton || false;

  const afterLoadEntity = (entity) => {
    setImages(entity.images);
    setEntity(entity);
    if (customAfterLoadEntity) {
      customAfterLoadEntity(entity);
    }
  };

  const onChange = (value) => {
    setRecaptched(true);
  };

  const onExpired = (value) => {
    setRecaptched(false);
  };

  useEffect(() => {
    setLoadingEntity(false);

    if (loadImages) {
      initialRoute();
      loadingData();
      dispatch(CarouselActions.setLoadImages(false));
    }
  }, [loadImages]);

  const afterInitialState = () => {
    setImages([]);
    setEntity(null);
    if (customAfterInitialState) {
      customAfterInitialState();
    }
  };

  const afterSubmit = (values, createdEntity) => {
    if (!entity && images.length > 0) {
      api
        .doUploadImage(imageContext, createdEntity, images[0])
        .then(setImages([]));
    }
  };

  const initialState = () => {
    history.push(`/${modelName}`);
    afterInitialState();
  };

  const newState = () => {
    history.push(`/${modelName}/new`);
    afterInitialState();
  };

  const updateFormProps = (result) => {
    const data = result.data;
    if (props.customUpdateFormProps) {
      props.customUpdateFormProps(data);
    }
    Object.keys(data).forEach((field) => {
      props.dispatch(change(formName, field, data[field]));
    });

    afterLoadEntity(data);
  };

  const loadingData = () => {
    return id && !entity && !noLoadData;
  };

  const initialRoute = async () => {
    if (!loadingEntity && id && id !== "new") {
      setLoadingEntity(true);
      if (navigator.onLine) {
        api
          .get(`${modelName}/${id}`)
          .then((entity) => {
            updateFormProps(entity);
            props.dispatch(change(formName, "submit_type", "update"));
          })
          .catch((err) => {});
      } else {
        await getTableToArray()
          .then((entity) => {
            updateFormProps(entity);
            props.disabled(change(formName, "submit_type", "update"));
          })
          .catch((err) => {});
      }
    }
  };

  const onSubmit = async (values) => {
    if (props.customBeforeSubmit) {
      values = props.customBeforeSubmit(values);
    }

    let offline = false;
    if (!navigator.onLine) offline = true;

    if (!offline) {
      if (values.submit_type === "update") {
        return api
          .put(`${modelName}/${values.id}`, values)
          .then((res) => {
            ToastMessage.success(
              `Os dados do ${label} foram alterados com sucesso!`
            );
            if (afterSubmit) {
              afterSubmit(values, res.data);
            }

            if (customAfterSubmit) {
              customAfterSubmit(res.data);
            }

            if (routeAfterSubmit) {
              history.push(routeAfterSubmit.replace(":id", res.data.id));
            } else {
              initialState();
              props.reset();
            }
          })
          .catch((err) => {
            ToastMessage.error(
              `Não foi possivel alterar os dados do ${label}!`
            );
          });
      } else {
        return api
          .post(`${modelName}`, values)
          .then((res) => {
            ToastMessage.success(`${label} cadastrado com sucesso!`);
            if (afterSubmit) {
              afterSubmit(values, res.data);
            }

            if (routeAfterSubmit) {
              history.push(routeAfterSubmit.replace(":id", res.data.id));
            } else if (keepUpdateAfterSubmit) {
              history.push(`/${modelName}/${res.data.id}`);
            } else {
              initialState();
              props.reset();
            }
          })
          .catch((err) => {
            ToastMessage.error(`Não foi possivel cadastrar o ${label}!`);
          });
      }
    } else {
      return await addPesquisa(values)
        .then((res) => {
          ToastMessage.success(`${label} cadastrado com sucesso!`);
          // if (afterSubmit) {
          //     afterSubmit(values, res.data);
          // }

          // if (routeAfterSubmit) {
          //     history.push(routeAfterSubmit.replace(":id", res.data.id));
          // } else if (keepUpdateAfterSubmit) {
          //     history.push(`/${modelName}/${res.data.id}`);
          // } else {
          //     initialState();
          //     props.reset();
          // }
        })
        .catch((err) => {
          ToastMessage.error(`Não foi possivel cadastrar o ${label}!`);
        });
    }
  };

  const deleteImage = async (image) => {
    if (entity) {
      await remoteDeleteImage(
        entity,
        image,
        image.context.replace(`/${image.filename}`)
      ).then((newImages) => setImages(newImages));
    } else {
      const newImages = images.filter((img) => img.context !== image.context);
      setImages(newImages);
    }
  };

  initialRoute();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className="cardPersonalData">
        <CardHeader title={`Dados do ${label}`} />
        <Divider />
        <CardContent>
          {loadingData() ? <div>Carregando...</div> : content}
          {/* // TODO: ajeitar o carousel
                    // verifica se é tipo visita e cria um novo componente de carousel
                    // o componente recebe a entidade que possui todos os dados, incluindo a imagem como base64 e a renderiza na tela */}
          {entity && imageContext && imageContext === "visita" && (
            <Carousel2
              loading={!loadingEntity}
              entity={entity}
              images={images}
              addCallback={uploadFile}
              deleteCallback={deleteImage}
              imageContext={imageContext}
              maxImages={maxImages}
              hiddenRemoveImgButton={hiddenRemoveImgButton}
            />
          )}
          {entity && imageContext && imageContext !== "visita" && (
            <Carousel
              loading={!loadingEntity}
              entity={entity}
              images={images}
              addCallback={uploadFile}
              deleteCallback={deleteImage}
              imageContext={imageContext}
              maxImages={maxImages}
              hiddenRemoveImgButton={hiddenRemoveImgButton}
            />
          )}
        </CardContent>

        <Divider />
      </Card>

      <Card>
        <CardActions style={{ margin: "1rem" }}>
          <Grid
            container
            direction="row"
            justify="space-evenly"
            alignItems="center"
          >
            <Grid item xs={12}>
              {needRecaptch && (
                <center>
                  <ReCAPTCHA
                    sitekey={
                      process.env.SITE_KEY ||
                      "6LcRTRYqAAAAAA72KJMvP2V7-qv8HElc9pbB5D79"
                    }
                    onChange={onChange}
                    onExpired={onExpired}
                    // className={classes.recaptchabox}
                  />
                  <br></br>
                </center>
              )}
            </Grid>

            {!hiddenBackButton && (
              <Button
                type="button"
                color="inherit"
                variant="contained"
                onClick={() => {
                  history.goBack();
                }}
              >
                Voltar
              </Button>
            )}

            {entity && !hiddenNewButton && (
              <Button
                type="button"
                color="inherit"
                variant="contained"
                disabled={pristine}
                onClick={() => {
                  reset();
                  newState();
                }}
              >
                Novo
              </Button>
            )}

            {!hiddenSaveButton && (
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!valid || submitting || (!recaptched && needRecaptch)}
              >
                Salvar
              </Button>
            )}
          </Grid>
        </CardActions>
      </Card>
    </form>
  );
};

export default BasicForm;
